<template>
  <div class="pageview">
    <!-- <van-nav-bar title="社区服务" fixed /> -->
    <!-- banner切换 -->
    <van-swipe class="homebanner"
      :autoplay="3000"
      indicator-color="white">
      <van-swipe-item v-for="banner in bannerImgs"
        :key="banner"><img :src="banner" /></van-swipe-item>
    </van-swipe>

    <h3 class="grouptitle">社区服务</h3>
    <van-grid class="wktype"
      :border="false">
      <van-grid-item icon="photo-o"
        @click="gotoUrl('/work')">
        <van-image class="iconimg"
          :src="require('../../assets/wangshangbanshi.png')"></van-image>
        <span class="wktext">网上办事</span>
      </van-grid-item>
      <van-grid-item icon="photo-o"
        @click="gotoUrl('/activity')">
        <van-image class="iconimg"
          :src="require('../../assets/shequhuodong.png')"></van-image>
        <span class="wktext">社区活动</span>
      </van-grid-item>
      <van-grid-item icon="photo-o"
        @click="gotoUrl('/article')">
        <van-image class="iconimg"
          :src="require('../../assets/zixunzhongxin.png')"></van-image>
        <span class="wktext">社区资讯</span>
      </van-grid-item>
      <template v-if="area == '栾川城关镇'">
        <van-grid-item icon="photo-o"
          @click="gotoUrl('/register')">
          <van-image class="iconimg"
            :src="require('../../assets/shequfengcai.png')"></van-image>
          <span class="wktext">信息登记</span>
        </van-grid-item>
      </template>
      <template v-else>
        <van-grid-item icon="photo-o"
          @click="gotoUrl('/scenery')">
          <van-image class="iconimg"
            :src="require('../../assets/shequfengcai.png')"></van-image>
          <span class="wktext">社区风采</span>
        </van-grid-item>
      </template>
      <van-grid-item icon="photo-o"
        @click="gotoUrl('/partymember')">
        <van-image class="iconimg"
          :src="require('../../assets/zhihuidangjian.png')"></van-image>
        <span class="wktext">智慧党建</span>
      </van-grid-item>
      <template v-if="area == '栾川城关镇' || area == '重庆路'">
        <van-grid-item icon="photo-o"
          @click="gotoUrl('/village')">
          <van-image class="iconimg"
            :src="require('../../assets/shijianyinhang.png')"></van-image>
          <span class="wktext">我的社区</span>
        </van-grid-item>
      </template>
      <template v-else>
        <van-grid-item icon="photo-o"
          @click="gotominiProgram('shijianyinhang')">
          <van-image class="iconimg"
            :src="require('../../assets/shijianyinhang.png')"></van-image>
          <span class="wktext">时间银行</span>
        </van-grid-item>
      </template>
      <template v-if="area == '栾川城关镇'">
        <van-grid-item icon="photo-o"
          @click="gotoUrl('/notice')">
          <van-image class="iconimg"
            :src="require('../../assets/qiuzhizhaopin.png')"></van-image>
          <span class="wktext">通知公告</span>
        </van-grid-item>
      </template>
      <template v-else>
        <van-grid-item icon="photo-o"
          @click="gotoUrl('/list')">
          <van-image class="iconimg"
            :src="require('../../assets/qiuzhizhaopin.png')"></van-image>
          <span class="wktext">求职招聘</span>
        </van-grid-item>
      </template>
      <van-grid-item icon="photo-o"
        @click="gotoUrl('/shop')">
        <van-image class="iconimg"
          :src="require('../../assets/bianminshangdian.png')"></van-image>
        <span class="wktext">便民商店</span>
      </van-grid-item>
    </van-grid>
    <div>
      <router-link to="/register">
        <img style="width:90%;margin:8px 5%"
          src="../../assets/dengji.png"
          alt="">
      </router-link>
    </div>
    <br class="brakline" />
    <h3 class="grouptitle">通知公告</h3>
    <div class="information">
      <div class="center"
        v-for="item in NoticeList"
        :key="item.index">
        <div @click="gotoUrl('/notice/detail/' + item.RIId)">
          <div class="centerTitle van-ellipsis">{{ item.Title }}</div>
          <div class="Content">
            <div style="display: inline-block"
              class="ContentA">
              <img v-if="item.ThematicUrl"
                :src="item.ThematicUrl"
                alt="" />
            </div>
            <div style="display: inline-block"
              class="ContentB">
              <span class="van-multi-ellipsis--l3">{{ item.Summary }}</span>
              <br />
              <span class="centertime">{{ item.IssTime }}</span>
            </div>
          </div>
        </div>
      </div>
      <van-empty description="暂无通知"
        v-if="!NoticeList.length" />
    </div>
    <!-- <tabbar :active="0" /> 
    <TipsMiniProject :show="false" /> -->
  </div>
</template>

<script>
import config from "@/config/index";
// import { mapGetters } from 'vuex'
import { WeGetRealInfoPage } from "@/api/RealInfo";
import { WeGetAppletInfo } from "@/api/index";
// import TipsMiniProject from "@/components/TipsMiniProject";
// import Tabbar from "@/components/Tabbar";
import wx from "weixin-js-sdk";
import { setOpenId, getOpenId } from "@/utils/auth";
export default {
  // computed: {
  //   ...mapGetters([
  //     'openId',
  //   ])
  // },
  components: {
    // Tabbar,
    // TipsMiniProject,
  },
  data () {
    return {
      appInfo: {},
      bannerImgs: [],
      active: 0,
      NoticeList: [],
      area: ''
    };
  },
  created () {
    this.getAppInfo();
    // 获取并保存openid
    if (this.$route.query["openid"]) {
      // this.$store.dispatch('user/setopenid', { openid: this.$route.query['openid'] })
      setOpenId(this.$route.query["openid"]);
    }
    this.area = config.area;
    this.getNoticeList();
  },
  methods: {
    //跳转到公共页面
    gotoUrl (path) {
      if (window.__wxjs_environment === "miniprogram") {
        window.wx.miniProgram.navigateTo({
          url: "../public/goto?path=" + path,
        });
      } else {
        this.$router.push(path);
      }
    },
    //小程序跳转到小程序
    gotominiProgram (name) {
      if (window.__wxjs_environment === "miniprogram") {
        window.wx.miniProgram.navigateTo({
          url: "../public/openminip?name=" + name,
        });
      } else {
        alert("请在小程序中打开");
      }
    },
    // 通知消息
    getNoticeList () {
      WeGetRealInfoPage({ riKind: 1 }).then((res) => {
        this.NoticeList = res.data.data;
      });
    },
    // 小程序展示数据
    getAppInfo () {
      WeGetAppletInfo().then((res) => {
        var list = res.data;
        if (list.code == 0) {
          var imgs = [];
          imgs[0] = list.data.SysPic1Url;
          imgs[1] = list.data.SysPic2Url;
          imgs[2] = list.data.SysPic3Url;
          this.bannerImgs = imgs;
          this.appInfo = list.data;
        }
      });
    },
  },
};
</script>

<style scoped>
.homebanner img {
  width: 100%;
  vertical-align: top;
}
.wktime {
  padding: 0 20px;
}
.wktype {
  padding: 0 10px 10px;
}
.orginview .iconimg {
  width: 62%;
}
.orginview .linktext {
  font-size: 14px;
  margin-top: 10px;
}
.wktype .iconimg {
  width: 65%;
}
.wktype .wktext {
  margin-top: 10px;
  font-size: 15px;
}
.grouptitle {
  border-left: 4px solid #1989fa;
  text-indent: 15px;
  font-weight: normal;
  font-size: 15px;
  margin: 12px 10px;
  color: #000;
}
.brakline {
  height: 5px;
  width: 100%;
  background: #eee;
  display: block;
  content: '';
}
</style>